import React, { useState } from "react";
import style from "./style.module.scss";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Button from "@local/components/button";
import { ROLES } from "@local/legacy-utils/constants";
import Modal from "@local/components/modal";
import FieldGroup from "@local/components/field-group";
import { reduxForm, Field, isValid } from "redux-form";
import { isRequired } from "@local/utils/validators";
import FieldText from "@local/components/field-text";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { getPseaDetails, updatePseaRevisionJustification } from "@local/actions";
import { checkPermission, AGENCY_PERMISSIONS } from "@local/legacy-utils/permissions";

const JustificationSection = ({ revisionToJustify, role, isJustificationFormValid, justificationFormValues, getPseaDetails, updatePseaRevisionJustification, hasAddJustificationToProceedPermission, isAddJustificationAvailable }) => {
  const [currentRevisionId, setCurrentRevisionId] = useState(undefined);

  const onClickAddJustification = (revisionId) => setCurrentRevisionId(revisionId);

  const onClickCancelJustification = () => setCurrentRevisionId(undefined);

  const onClickSubmitJustification = async () => {
    try {
      await updatePseaRevisionJustification(currentRevisionId, justificationFormValues);
      getPseaDetails();
      setCurrentRevisionId(undefined);
    } catch (error) {
      toast.error(
        formatMessage({
          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.justification.section.idx.unableToSaveData",
        }),
      );
    }
  };

  // Leave the code commented out for now to show justifications even after PSEA reach full capacity.
  // if (!(revisionToJustify?.revision_rating === "low" || revisionToJustify?.revision_rating === "medium" || revisionToJustify?.revision_rating === "not_eligible")) {
  //   return <></>;
  // }

  return (
    <>
      <div className={style.mainWrapper}>
        {role === ROLES.AGENCY ? (
          <>
            {
              <div className={style.justificationWrapper}>
                {revisionToJustify.justification_to_proceed ? (
                  <>
                    <pre className={style.justificationToProceed}>{revisionToJustify.justification_to_proceed}</pre>
                    {isAddJustificationAvailable ? (
                      <>
                        <span>
                          {formatMessage({
                            id: "pages.partner.details.cmp.psea.cmp.mn.cmp.justification.section.idx.justificationLabel",
                          })}
                        </span>
                        <Button
                          onClick={() => onClickAddJustification(revisionToJustify.id)}
                          text={formatMessage({
                            id: "pages.partner.details.cmp.psea.cmp.mn.cmp.justification.section.idx.justificationButton",
                          })}
                          isDisabled={!hasAddJustificationToProceedPermission}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <>
                    {isAddJustificationAvailable ? (
                      <>
                        <span>
                          {formatMessage({
                            id: "pages.partner.details.cmp.psea.cmp.mn.cmp.justification.section.idx.justificationLabel",
                          })}
                        </span>
                        <Button
                          onClick={() => onClickAddJustification(revisionToJustify.id)}
                          text={formatMessage({
                            id: "pages.partner.details.cmp.psea.cmp.mn.cmp.justification.section.idx.justificationButton",
                          })}
                          isDisabled={!hasAddJustificationToProceedPermission}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
            }
          </>
        ) : (
          <></>
        )}
      </div>
      <Modal
        isOpen={currentRevisionId !== undefined}
        onClose={onClickCancelJustification}
        title={formatMessage({
          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.justification.section.idx.addRevisionJustificationModalTitle",
        })}
        footer={
          <>
            <div>
              <Button
                onClick={onClickCancelJustification}
                variant="text"
                text={formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.justification.section.idx.cancel",
                })}
              />
            </div>
            <div>
              <Button
                isDisabled={!isJustificationFormValid}
                onClick={() => onClickSubmitJustification()}
                text={formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.justification.section.idx.button",
                })}
              />
            </div>
          </>
        }
      >
        <>
          <FieldGroup>
            <Field
              component={FieldText}
              validate={[isRequired]}
              id="justification_to_proceed"
              name="justification_to_proceed"
              label={formatMessage({
                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.justification.section.idx.justificationField",
              })}
              placeholder={formatMessage({
                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.justification.section.idx.justificationField",
              })}
              type="text"
              withCharactersCount
              maxLength={500}
            />
          </FieldGroup>
        </>
      </Modal>
    </>
  );
};
JustificationSection.propTypes = {};

const mapStateToProps = (state) => {
  const role = state?.session?.role;
  return {
    role: role,
    isJustificationFormValid: isValid("pseaFinalDeterminationJustificationForm")(state),
    justificationFormValues: state?.form?.pseaFinalDeterminationJustificationForm?.values,
    hasAddJustificationToProceedPermission: role === ROLES.AGENCY ? checkPermission(AGENCY_PERMISSIONS.COMPLETE_PSEA_SELF_ASSESSMENT, state) : false,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getPseaDetails: () => dispatch(getPseaDetails(undefined, { partnerId: ownProps.partnerId })),
  updatePseaRevisionJustification: (revisionId, data) =>
    dispatch(
      updatePseaRevisionJustification(data, {
        partnerId: ownProps.partnerId,
        revisionId,
      }),
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: "pseaFinalDeterminationJustificationForm",
    enableReinitialize: true,
  })(JustificationSection),
);
