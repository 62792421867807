/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { reset } from "redux-form";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Modal from "@local/components/modal";
import Alert from "@local/components/alert";
import Chip from "@mui/material/Chip";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Avatar from "@mui/material/Avatar";
import { Grid } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Card from "@local/components/card";
import Pagination from "@local/components/pagination";
import Button from "@local/components/button";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Loader from "@local/components/loader";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  getReviewersList,
  sendReminder,
  getReviewSummary,
  updateReviewSummary,
  getOpenProjectApplicationsPreselectedList,
  getOpenProjectApplicationsRecommendedList,
  updateProjectApplication,
  getPartnerProjectApplication,
  sendForDecision,
  getApplicationFeedbackList,
  getProjectApplicationDetails,
} from "@local/actions";
import { checkPermission, PARTNER_PERMISSIONS, AGENCY_PERMISSIONS } from "@local/legacy-utils/permissions";
import { APPLICATION_STATUSES, ROLES } from "@local/legacy-utils/constants";
import ManageReviewSummaryForm from "./components/manage-review-summary-form";
import PartnerResultForm from "./components/partner-result-form";
import FeedbackItem from "@local/components/feedback-item";
import style from "./style.module.scss";
import UpdateApplicationModal from "./components/update-application-modal";
import ConfirmModal from "@local/components/confirm-modal";
import { formatDate } from "@local/utils";
import { SELECTION_CRITERIA } from "@local/utils/constants";
import { formatDateForPrint } from "@local/legacy-utils/dates";
import SanctionStatus from "@local/components/sanction-status";
import VerificationIcon from "@local/components/verification-icon";
import { formatNumberDecimals } from "@local/utils/adapters";

const OpenProjectResults = ({
  project,
  getReviewers,
  getReviewSummary,
  isProjectCompleted,
  reviewersLoading,
  reviewSummaryLoading,
  reviewers,
  reviewSummary,
  isFocalPoint,
  notifyReviewer,
  isAddSummaryAllowed,
  manageReviewSummaryFormValues,
  updateReviewSummary,
  deadlinePassed,
  partnerAccepted,
  getRecommendedPartners,
  getPreselectedPartners,
  role,
  partnerProjectApplication,
  notifyDate,
  hasPermissionToAnswerApplication,
  getPartnerProjectApplication,
  resetReviewSummaryForm,
  isCreator,
  hasSelectRecommendedPermission,
  updateApplicationFormValues,
  resetUpdateApplicationForm,
  updateProjectApplication,
  hasRecommendedPartner,
  isSend,
  sendForDecision,
  getApplicationFeedbacks,
  applicationId,
  openProjectApplicationsRecommendedList,
  openProjectApplicationsPreselectedList,
  openProjectApplicationsRecommendedCount,
  openProjectApplicationsPreselectedCount,
  getProjectApplicationDetails,
  projectApplication,
}) => {
  const [isSendForDecisionModalVisible, setIsSendForDecisionModalVisible] = useState(false);
  const [isManageReviewSummaryModalVisible, setIsManageReviewSummaryModalVisible] = useState(false);
  const [updateApplicationAction, setUpdateApplicationAction] = useState(false);
  const [updateApplicationActionValue, setUpdateApplicationActionValue] = useState(false);
  const [currentToUpdateApplication, setCurrentToUpdateApplication] = useState(undefined);
  const [expandedItem, setExpandedItem] = useState(undefined);
  const [recommendedPartnersPage, setRecommendedPartnersPage] = useState(1);
  const [preselectedPartnersPage, setPreselectedPartnersPage] = useState(1);

  const itemsPerPageForPagination = 5;

  useEffect(() => {
    getRecommendedPartners(recommendedPartnersPage);
    getPreselectedPartners();
    if (role === ROLES.AGENCY) {
      getReviewers();
      getReviewSummary();
    } else {
      getPartnerProjectApplication();
      if (applicationId) getApplicationFeedbacks(applicationId);
    }
  }, []);

  const onClickOpenManageReviewSummaryModal = () => setIsManageReviewSummaryModalVisible(true);

  const onClickCloseManageReviewSummaryModal = () => {
    setIsManageReviewSummaryModalVisible(false);
    resetReviewSummaryForm();
  };

  const onClickNotifyReviewer = async (eoiId, reviewerId) => {
    try {
      const response = await notifyReviewer(eoiId, reviewerId);
      toast.success(response?.data?.success);
    } catch (error) {
      toast.error(
        formatMessage({
          id: "pages.project.details.cmp.open.project.results.idx.reminderError",
        }),
      );
    }
  };

  const onClickSubmitManageReviewSummary = async () => {
    try {
      const adaptedValues = {
        ...manageReviewSummaryFormValues,
        review_summary_attachment: manageReviewSummaryFormValues?.review_summary_attachment?.id,
      };
      await updateReviewSummary(adaptedValues);
      await getReviewSummary();
      setIsManageReviewSummaryModalVisible(false);
      resetReviewSummaryForm();
      getRecommendedPartners(recommendedPartnersPage);
    } catch (error) {
      toast.error(
        formatMessage({
          id: "pages.project.details.cmp.open.project.results.idx.changeSummaryFormError",
        }),
      );
    }
  };

  const onClickUpdateApplication = (application, action, value) => {
    setUpdateApplicationAction(action);
    setUpdateApplicationActionValue(value);
    setCurrentToUpdateApplication(application);
  };

  const onClickCancelUpdateApplication = () => {
    setCurrentToUpdateApplication(undefined);
    setUpdateApplicationAction(undefined);
    setUpdateApplicationActionValue(undefined);
    resetUpdateApplicationForm();
  };

  const onClickSubmitUpdateApplication = async () => {
    try {
      await updateProjectApplication(currentToUpdateApplication.id, {
        [updateApplicationAction]: updateApplicationActionValue,
        ...updateApplicationFormValues,
      });
      await getReviewSummary();
      setCurrentToUpdateApplication(undefined);
      setUpdateApplicationAction(undefined);
      resetUpdateApplicationForm();
      getRecommendedPartners(recommendedPartnersPage);
    } catch (error) {
      toast.error(
        formatMessage({
          id: "pages.project.details.cmp.open.project.results.idx.changeSummaryFormError",
        }),
      );
    }
  };

  const onClickCloseSendForDecisionModal = () => setIsSendForDecisionModalVisible(false);

  const onClickSubmitSendForDecisionModal = async () => {
    await sendForDecision();
    onClickCloseSendForDecisionModal();
  };

  const handleRecommendedPartner = async (item) => {
    setExpandedItem((state) => (state === item.id ? undefined : item.id));
    if (item.id !== expandedItem) await getProjectApplicationDetails(item.id);
  };

  const handleRecommendedPartnerPageChange = async (event, value) => {
    try {
      setRecommendedPartnersPage(value);
      await getRecommendedPartners(value);
    } catch (error) {
      toast.error(
        formatMessage({
          id: "pages.project.details.cmp.open.project.results.idx.saveProjectError",
        }),
      );
    }
  };

  const handlePreselectedPartnersPageChange = async (event, value) => {
    try {
      setPreselectedPartnersPage(value);
      await getPreselectedPartners(value);
    } catch (error) {
      toast.error(
        formatMessage({
          id: "pages.project.details.cmp.open.project.results.idx.saveProjectError",
        }),
      );
    }
  };

  const handleApplicationItem = (application, summary) => {
    if ((isCreator || isFocalPoint) && hasSelectRecommendedPermission) {
      if (application?.did_win && application?.did_withdraw && !application?.did_accept && !application?.did_decline) {
        return (
          <div style={{ textAlign: "right" }}>
            <Button
              isDisabled
              text={formatMessage({
                id: "pages.project.details.cmp.open.project.results.idx.retracted",
              })}
            />
          </div>
        );
      } else if (application?.did_win && !application?.did_withdraw && application?.did_accept && !application?.did_decline) {
        return (
          <div style={{ textAlign: "right" }}>
            <Button
              isDisabled
              text={formatMessage({
                id: "pages.project.details.cmp.open.project.results.idx.accepted",
              })}
            />
          </div>
        );
      } else if (application?.did_win && !application?.did_withdraw && !application?.did_accept && application?.did_decline) {
        return (
          <div style={{ textAlign: "right" }}>
            <Button
              isDisabled
              text={formatMessage({
                id: "pages.project.details.cmp.open.project.results.idx.declined",
              })}
            />
          </div>
        );
      } else if (application?.did_win && !application?.did_withdraw && !application?.did_accept && !application?.did_decline) {
        return (
          <div style={{ textAlign: "right" }}>
            <span>
              <Button
                text={formatMessage({
                  id: "pages.project.details.cmp.open.project.results.idx.withdraw",
                })}
                variant="text"
                onClick={() => onClickUpdateApplication(application, "did_win", false)}
              />
            </span>
          </div>
        );
      } else if (!application?.did_win && !summary?.review_summary_comment) {
        return (
          <div style={{ textAlign: "right" }}>
            <Tooltip
              title={formatMessage({
                id: "pages.project.details.cmp.open.project.results.idx.selectInfo",
              })}
            >
              <span>
                <Button
                  isDisabled
                  text={formatMessage({
                    id: "pages.project.details.cmp.open.project.results.idx.select",
                  })}
                />
              </span>
            </Tooltip>
          </div>
        );
      } else if (!application?.did_win && !application?.partner_additional?.is_verified) {
        return (
          <div style={{ textAlign: "right" }}>
            <Tooltip
              title={formatMessage({
                id: "pages.project.details.cmp.open.project.results.idx.verifiedInfo",
              })}
            >
              <span>
                <Button
                  isDisabled
                  text={formatMessage({
                    id: "pages.project.details.cmp.open.project.results.idx.select",
                  })}
                />
              </span>
            </Tooltip>
          </div>
        );
      } else if (!application?.did_win && (application?.partner_additional?.has_potential_sanction_match || application?.partner_additional?.has_sanction_match)) {
        return (
          <div style={{ textAlign: "right" }}>
            <Tooltip
              title={formatMessage({
                id: "pages.project.details.cmp.open.project.results.idx.sanctionMatch",
              })}
            >
              <span>
                <Button
                  isDisabled
                  text={formatMessage({
                    id: "pages.project.details.cmp.open.project.results.idx.select",
                  })}
                />
              </span>
            </Tooltip>
          </div>
        );
      } else if (!application?.did_win) {
        return (
          <div style={{ textAlign: "right" }}>
            <Button
              text={formatMessage({
                id: "pages.project.details.cmp.open.project.results.idx.select",
              })}
              onClick={() => onClickUpdateApplication(application, "did_win", true)}
            />
          </div>
        );
      }
    } else {
      return <></>;
    }
  };

  if (role === ROLES.AGENCY)
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Card
                      title={formatMessage({
                        id: "pages.project.details.cmp.open.project.results.idx.title",
                      })}
                      action={
                        !hasSelectRecommendedPermission ? (
                          !isProjectCompleted &&
                          (!hasRecommendedPartner || !reviewSummary?.review_summary_comment ? (
                            <Tooltip
                              title={
                                !hasRecommendedPartner
                                  ? formatMessage({
                                      id: "pages.project.details.cmp.open.project.results.idx.addRecommendedPartner",
                                    })
                                  : formatMessage({
                                      id: "pages.project.details.cmp.open.project.results.idx.addSummary",
                                    })
                              }
                            >
                              <span>
                                <Button
                                  text={formatMessage({
                                    id: "pages.project.details.cmp.open.project.results.idx.text",
                                  })}
                                  isDisabled
                                />
                              </span>
                            </Tooltip>
                          ) : (
                            <Button
                              text={
                                isSend
                                  ? formatMessage({
                                      id: "pages.project.details.cmp.open.project.results.idx.send",
                                    })
                                  : formatMessage({
                                      id: "pages.project.details.cmp.open.project.results.idx.text",
                                    })
                              }
                              onClick={() => setIsSendForDecisionModalVisible(true)}
                            />
                          ))
                        ) : (
                          <></>
                        )
                      }
                    >
                      {openProjectApplicationsRecommendedList?.length > 0 ? (
                        <>
                          {openProjectApplicationsRecommendedList.map((item) => (
                            <div key={`recommended_partner_${item.id}`}>
                              <div className={style.singleItemWrapper}>
                                {
                                  <div className={style.singleItemExpandWrapper}>
                                    <IconButton onClick={() => handleRecommendedPartner(item)}>{item.id === expandedItem ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>
                                  </div>
                                }
                                <div className={style.singleItemNameWrapper}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {item?.legal_name}
                                    <div style={{ marginRight: ".25rem" }}>
                                      <VerificationIcon profileStatus={item?.partner_additional?.profile_status} />
                                    </div>
                                    <SanctionStatus sanction={item?.partner_additional?.sanctions_status} />
                                  </div>
                                </div>
                                <div className={style.singleItemActionWrapper}>{<div>{handleApplicationItem(item, reviewSummary)}</div>}</div>
                              </div>
                              {item.id === expandedItem ? (
                                <div className={style.expandedSectionWrapper}>
                                  {projectApplication?.id === item?.id ? (
                                    <>
                                      <div className={style.caption}>
                                        <span>
                                          {formatMessage({
                                            id: "pages.project.details.cmp.open.project.results.idx.criteria",
                                          })}
                                        </span>
                                        <span>
                                          {formatMessage({
                                            id: "pages.project.details.cmp.open.project.results.idx.score",
                                          })}
                                        </span>
                                      </div>
                                      <div className={style.criteriaWrapper}>
                                        {Object.keys(item?.average_scores)?.map((key) => (
                                          <div key={`recommended_average_scores_${item?.id}`} className={style.criteriaItemWrapper}>
                                            <div>
                                              <span>
                                                {SELECTION_CRITERIA()[key.substring(0, 3)]} {key.startsWith("Oth") ? `- ${item.your_score_breakdown[key].description}` : ""}
                                              </span>
                                            </div>
                                            <div>
                                              <span>{formatNumberDecimals(item?.average_scores[key])}</span>
                                            </div>
                                          </div>
                                        ))}
                                        <div key={`recommended_total_average_score_${item?.id}`} className={style.criteriaItemWrapper}>
                                          <div>
                                            <span>
                                              {formatMessage({
                                                id: "pages.project.details.cmp.open.project.results.idx.totalScore",
                                              })}
                                            </span>
                                          </div>
                                          <div>{formatNumberDecimals(item?.average_total_score)}</div>
                                        </div>
                                        <div key={`recommended_number_of_assessments_${item?.id}`} className={style.criteriaItemWrapper}>
                                          <div>
                                            <span>
                                              {formatMessage({
                                                id: "pages.project.details.cmp.open.project.results.idx.numberOfAssessments",
                                              })}
                                            </span>
                                          </div>
                                          <div>{item?.completed_assessments_count}</div>
                                        </div>
                                      </div>
                                      <div className={style.additionalInformationWrapper}>
                                        <div>
                                          <span className={style.additionalInformationLabel}>
                                            {formatMessage({
                                              id: "pages.project.details.cmp.open.project.results.idx.notes",
                                            })}
                                          </span>
                                        </div>
                                        {item?.assessments?.map((assessment) => (
                                          <div className={style.additionalInformationItemWrapper}>
                                            <div>
                                              <div className={style.additionalInformationDescription}>
                                                <span>{assessment?.reviewer_fullname}</span>
                                              </div>
                                              <div>
                                                <span>{assessment?.note ?? "-"}</span>
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                      <div className={style.criteriaWrapper}>
                                        {projectApplication?.did_win ? (
                                          <div key={`recommended_selection_justification_${item?.id}`} className={style.columnCriteriaItemWrapper}>
                                            <div>
                                              <span>
                                                {formatMessage({
                                                  id: "pages.project.details.cmp.open.project.results.idx.selectionJustification",
                                                })}
                                              </span>
                                            </div>
                                            <div>
                                              <div>
                                                <span>
                                                  {projectApplication?.agency_decision_maker?.name} {projectApplication?.agency_decision_date ? formatDateForPrint(projectApplication?.agency_decision_date) : ""}
                                                </span>
                                              </div>
                                              <div>
                                                <span className={style.captionText}>{projectApplication?.justification_reason}</span>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                        {projectApplication?.did_withdraw ? (
                                          <div key={`recommended_retract_justification_${item?.id}`} className={style.columnCriteriaItemWrapper}>
                                            <div>
                                              <span>
                                                {formatMessage({
                                                  id: "pages.project.details.cmp.open.project.results.idx.retractJustification",
                                                })}
                                              </span>
                                            </div>
                                            <div>
                                              <span>{projectApplication?.withdraw_reason}</span>
                                            </div>
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                        {projectApplication?.did_accept || projectApplication?.did_decline ? (
                                          <div key={`recommended_partner_decision_${item?.id}`} className={style.criteriaItemWrapper}>
                                            <div>
                                              <span>
                                                {formatMessage({
                                                  id: "pages.project.details.cmp.open.project.results.idx.partnerDecision",
                                                })}
                                              </span>
                                            </div>
                                            {projectApplication?.partner_decision_maker ? (
                                              <div>
                                                <span>
                                                  {projectApplication.did_accept
                                                    ? formatMessage({
                                                        id: "pages.project.details.cmp.open.project.results.idx.acceptedOne",
                                                      })
                                                    : formatMessage({
                                                        id: "pages.project.details.cmp.open.project.results.idx.declinedOne",
                                                      })}
                                                  {projectApplication?.partner_decision_maker?.name} - {formatDateForPrint(projectApplication?.partner_decision_date)}
                                                </span>
                                              </div>
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </>
                                  ) : (
                                    <div className={style.loader}>
                                      <Loader isVisible />
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          ))}
                          <Pagination id="recommended_partner_pagination" count={Math.ceil(openProjectApplicationsRecommendedCount / itemsPerPageForPagination)} page={recommendedPartnersPage} onPageChange={handleRecommendedPartnerPageChange} />
                        </>
                      ) : (
                        <span>
                          {formatMessage({
                            id: "pages.project.details.cmp.open.project.results.idx.noInfo",
                          })}
                        </span>
                      )}
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card
                      title={formatMessage({
                        id: "pages.project.details.cmp.open.project.results.idx.titleOne",
                      })}
                    >
                      {openProjectApplicationsPreselectedList?.length > 0 ? (
                        <>
                          {openProjectApplicationsPreselectedList.map((item) => (
                            <div key={`preselected_partner_${item.id}`}>
                              <div className={style.singleItemWrapper}>
                                {
                                  <div className={style.singleItemExpandWrapper}>
                                    <IconButton onClick={() => setExpandedItem((state) => (state === item.id ? undefined : item.id))}>{item.id === expandedItem ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>
                                  </div>
                                }
                                <div className={style.singleItemNameWrapper}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {item?.legal_name}
                                    <div style={{ marginRight: ".25rem" }}>
                                      <VerificationIcon profileStatus={item?.partner_additional?.profile_status} />
                                    </div>
                                    <SanctionStatus sanction={item?.partner_additional?.sanctions_status} />
                                  </div>
                                </div>
                              </div>
                              {item.id === expandedItem ? (
                                <div className={style.expandedSectionWrapper}>
                                  <div className={style.caption}>
                                    <span>
                                      {formatMessage({
                                        id: "pages.project.details.cmp.open.project.results.idx.criteriaOne",
                                      })}
                                    </span>
                                    <span>
                                      {formatMessage({
                                        id: "pages.project.details.cmp.open.project.results.idx.scoreOne",
                                      })}
                                    </span>
                                  </div>
                                  <div className={style.criteriaWrapper}>
                                    {Object.keys(item?.average_scores)?.map((key) => (
                                      <div key={`preselected_average_scores_${item?.id}`} className={style.criteriaItemWrapper}>
                                        <div>
                                          <span>
                                            {SELECTION_CRITERIA()[key.substring(0, 3)]} {key.startsWith("Oth") ? `- ${item.your_score_breakdown[key].description}` : ""}
                                          </span>
                                        </div>
                                        <div>
                                          <span>{formatNumberDecimals(item?.average_scores[key])}</span>
                                        </div>
                                      </div>
                                    ))}
                                    <div key={`preselected_total_average_score_${item?.id}`} className={style.criteriaItemWrapper}>
                                      <div>
                                        <span>
                                          {formatMessage({
                                            id: "pages.project.details.cmp.open.project.results.idx.totalScoreOne",
                                          })}
                                        </span>
                                      </div>
                                      <div>{formatNumberDecimals(item?.average_total_score)}</div>
                                    </div>
                                    <div key={`preselected_number_of_assessments_${item?.id}`} className={style.criteriaItemWrapper}>
                                      <div>
                                        <span>
                                          {formatMessage({
                                            id: "pages.project.details.cmp.open.project.results.idx.numberOfAssessmentsOne",
                                          })}
                                        </span>
                                      </div>
                                      <div>{item?.completed_assessments_count}</div>
                                    </div>
                                  </div>
                                  <div className={style.additionalInformationWrapper}>
                                    <div>
                                      <span className={style.additionalInformationLabel}>
                                        {formatMessage({
                                          id: "pages.project.details.cmp.open.project.results.idx.notesOne",
                                        })}
                                      </span>
                                    </div>
                                    {item?.assessments?.map((assessment) => (
                                      <div className={style.additionalInformationItemWrapper}>
                                        <div>
                                          <div className={style.additionalInformationDescription}>
                                            <span>{assessment?.reviewer_fullname}</span>
                                          </div>
                                          <div>
                                            <span>{assessment?.note ?? "-"}</span>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          ))}
                          <Pagination id="preselected_partners_pagination" count={Math.ceil(openProjectApplicationsPreselectedCount / itemsPerPageForPagination)} page={preselectedPartnersPage} onPageChange={handlePreselectedPartnersPageChange} />
                        </>
                      ) : (
                        <span>
                          {formatMessage({
                            id: "pages.project.details.cmp.open.project.results.idx.noInfoOne",
                          })}
                        </span>
                      )}
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className={style.cardWrapper}>
                  <Card
                    title={formatMessage({
                      id: "pages.project.details.cmp.open.project.results.idx.titleTwo",
                    })}
                  >
                    <Loader isVisible={reviewSummaryLoading} />
                    {reviewSummary?.review_summary_comment ? (
                      <div>
                        <div className={style.ContentWrapper}>
                          <div className={style.itemWrapper}>
                            <span className={style.itemLabel}>
                              {formatMessage({
                                id: "pages.project.details.cmp.open.project.results.idx.comment",
                              })}
                            </span>
                            <span className={style.itemValue}>{reviewSummary?.review_summary_comment}</span>
                          </div>
                          <div className={style.itemWrapper}>
                            <span className={style.itemLabel}>
                              {formatMessage({
                                id: "pages.project.details.cmp.open.project.results.idx.attachment",
                              })}
                            </span>
                            {reviewSummary?.review_summary_attachment ? (
                              <span className={style.itemValue}>
                                <Chip
                                  avatar={
                                    <Avatar sx={{ backgroundColor: "#C5C5C5" }}>
                                      <AttachFileIcon fontSize="small" style={{ fill: "#404040" }} />
                                    </Avatar>
                                  }
                                  className={style.attachmentName}
                                  label={reviewSummary?.review_summary_attachment?.name}
                                  onDelete={() => {}}
                                  deleteIcon={
                                    <a target="_blank" href={reviewSummary?.review_summary_attachment?.file_field} rel="noreferrer">
                                      <FileDownloadIcon fontSize="small" style={{ fill: "#606060" }} />
                                    </a>
                                  }
                                />
                              </span>
                            ) : (
                              <span className={style.itemValue}>--</span>
                            )}
                          </div>
                        </div>
                        {(!partnerAccepted || !isProjectCompleted) && deadlinePassed && isAddSummaryAllowed ? (
                          <div className={style.buttonWrapper}>
                            <Button
                              text={
                                reviewSummary?.review_summary_comment
                                  ? formatMessage({
                                      id: "pages.project.details.cmp.open.project.results.idx.labelEdit",
                                    })
                                  : formatMessage({
                                      id: "pages.project.details.cmp.open.project.results.idx.labelAdd",
                                    })
                              }
                              variant="text"
                              onClick={onClickOpenManageReviewSummaryModal}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    ) : (
                      <div>
                        <span>
                          {formatMessage({
                            id: "pages.project.details.cmp.open.project.results.idx.empty",
                          })}
                        </span>
                        {isAddSummaryAllowed ? (
                          <div className={style.buttonWrapper}>
                            <Button
                              text={
                                reviewSummary?.review_summary_comment
                                  ? formatMessage({
                                      id: "pages.project.details.cmp.open.project.results.idx.labelEdit",
                                    })
                                  : formatMessage({
                                      id: "pages.project.details.cmp.open.project.results.idx.labelAdd",
                                    })
                              }
                              variant="text"
                              onClick={onClickOpenManageReviewSummaryModal}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    )}
                  </Card>
                  <Modal
                    isOpen={isManageReviewSummaryModalVisible}
                    onClose={onClickCloseManageReviewSummaryModal}
                    title={
                      reviewSummary?.review_summary_comment
                        ? formatMessage({
                            id: "pages.project.details.cmp.open.project.results.idx.changeSummaryEditModalTitle",
                          })
                        : formatMessage({
                            id: "pages.project.details.cmp.open.project.results.idx.changeSummaryAddModalTitle",
                          })
                    }
                    small
                    footer={
                      <>
                        <Button
                          text={formatMessage({
                            id: "pages.project.details.cmp.open.project.results.idx.cancel",
                          })}
                          onClick={onClickCloseManageReviewSummaryModal}
                          variant="text"
                        />
                        <Button
                          text={formatMessage({
                            id: "pages.project.details.cmp.open.project.results.idx.ok",
                          })}
                          onClick={onClickSubmitManageReviewSummary}
                        />
                      </>
                    }
                  >
                    <Alert
                      type="info"
                      content={formatMessage({
                        id: "pages.project.details.cmp.open.project.results.idx.changeSummaryFormInfo",
                      })}
                      withBottomMargin
                    />
                    <ManageReviewSummaryForm initialValues={reviewSummary?.review_summary_comment ? reviewSummary : undefined} />
                  </Modal>
                </div>
                <div className={style.cardWrapper}>
                  <Card
                    title={formatMessage({
                      id: "pages.project.details.cmp.open.project.results.idx.titleThree",
                    })}
                  >
                    <Loader isVisible={reviewersLoading} />
                    {reviewers.length > 0 ? (
                      <div>
                        {reviewers?.map((reviewer) => (
                          <div className={style.reviewerWrapper} key={reviewer?.user_id}>
                            <div className={style.reviewerItem}>
                              <span className={style.reviewerName}>{reviewer?.user_name}</span>
                              <span className={style.captionCount}>{`${reviewer?.assessments?.counts} ${formatMessage({
                                id: "pages.project.details.cmp.open.project.results.idx.assessments",
                              })}`}</span>
                            </div>
                            {!isProjectCompleted && isFocalPoint && reviewer?.assessments?.send_reminder ? (
                              <div className={style.buttonWrapper}>
                                <Button
                                  text={formatMessage({
                                    id: "pages.project.details.cmp.open.project.results.idx.reminder",
                                  })}
                                  variant="text"
                                  onClick={() => onClickNotifyReviewer(reviewer?.assessments?.eoi_id, reviewer?.user_id)}
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <span>
                        {formatMessage({
                          id: "pages.project.details.cmp.open.project.results.idx.emptyOne",
                        })}
                      </span>
                    )}
                  </Card>
                </div>
                {isProjectCompleted ? (
                  <div className={style.cardWrapper}>
                    <Card
                      title={formatMessage({
                        id: "pages.project.details.cmp.open.project.results.idx.titleFour",
                      })}
                    >
                      <span>
                        {project?.justification ||
                          formatMessage({
                            id: "pages.project.details.cmp.open.project.results.idx.noJustification",
                          })}
                      </span>
                    </Card>
                  </div>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <UpdateApplicationModal isOpen={updateApplicationAction} onClose={onClickCancelUpdateApplication} onSubmit={onClickSubmitUpdateApplication} actionValue={updateApplicationActionValue} />
        <ConfirmModal
          isOpen={isSendForDecisionModalVisible}
          onSubmit={onClickSubmitSendForDecisionModal}
          onClose={onClickCloseSendForDecisionModal}
          message={formatMessage({
            id: "pages.project.details.cmp.open.project.results.idx.info",
          })}
        />
      </>
    );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Card
                title={formatMessage({
                  id: "pages.project.details.cmp.open.project.results.idx.titleFive",
                })}
              >
                {partnerProjectApplication?.did_win && notifyDate && !partnerProjectApplication?.did_withdraw ? (
                  <>
                    <PartnerResultForm
                      hasPermissionToAnswerApplication={hasPermissionToAnswerApplication}
                      application={partnerProjectApplication}
                      notifyDate={notifyDate}
                      isCnRequired={partnerProjectApplication?.is_cn_required}
                      refreshFunction={getPartnerProjectApplication}
                    />
                  </>
                ) : (
                  <>
                    {partnerProjectApplication?.did_withdraw ? (
                      <span>
                        {formatMessage({
                          id: "pages.project.details.cmp.open.project.results.idx.retractedOne",
                        })}
                      </span>
                    ) : (
                      <span>
                        {formatMessage({
                          id: "pages.project.details.cmp.open.project.results.idx.expected",
                        })}
                        {formatDate(notifyDate)}
                      </span>
                    )}
                  </>
                )}
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FeedbackItem allowedToAddFeedback={false} isCompleted={project?.is_completed} isCreator={isCreator} isFocalPoint={isFocalPoint} applicationId={partnerProjectApplication?.id} role={role} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

OpenProjectResults.propTypes = {};

const mapStateToProps = (state, ownProps) => ({
  isProjectCompleted: ownProps?.project?.is_completed || ownProps?.project?.completed_reason,
  reviewersLoading: state?.reviewersList?.loading,
  reviewers: state?.reviewersList?.data?.data ?? [],
  reviewSummaryLoading: state?.reviewSummary?.loading,
  reviewSummary: state?.reviewSummary?.data?.data ?? {},
  isFocalPoint: state?.session?.role === ROLES.AGENCY && (ownProps?.project?.focal_points?.includes(state?.session?.userId) || ownProps?.project?.created_by === state?.session?.userId),
  manageReviewSummaryFormValues: state?.form?.changeSummaryForm?.values,
  deadlinePassed: ownProps?.project?.deadline_passed,
  partnerAccepted: ownProps?.project?.contains_partner_accepted,
  role: state?.session?.role,
  notifyDate: ownProps?.project?.notif_results_date,
  hasPermissionToAnswerApplication: checkPermission(PARTNER_PERMISSIONS.CFEI_ANSWER_SELECTION, state),
  hasSelectRecommendedPermission: checkPermission(AGENCY_PERMISSIONS.CFEI_SELECT_RECOMMENDED_PARTNER, state),
  updateApplicationFormValues: state?.form?.updateApplicationForm?.values,
  isCreator: ownProps?.project?.created_by === state?.session?.userId,
  partnerProjectApplication: state?.partnerProjectApplication?.data?.data,
  openProjectApplicationsRecommendedList: state?.openProjectApplicationsRecommendedList?.data?.data?.results,
  openProjectApplicationsRecommendedCount: state?.openProjectApplicationsRecommendedList?.data?.data?.count,
  openProjectApplicationsPreselectedList: state?.openProjectApplicationsPreselectedList?.data?.data?.results,
  openProjectApplicationsPreselectedCount: state?.openProjectApplicationsPreselectedList?.data?.data?.count,
  projectApplication: state?.unProjectApplicationDetails?.data?.data,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getReviewers: () => dispatch(getReviewersList(undefined, { id: ownProps?.projectId })),
  notifyReviewer: (eoiId, reviewerId) => dispatch(sendReminder(undefined, { id: eoiId, reviewerId: reviewerId })),
  getReviewSummary: () => dispatch(getReviewSummary(undefined, { id: ownProps?.projectId })),
  updateReviewSummary: (body) => dispatch(updateReviewSummary(body, { id: ownProps?.projectId })),
  resetReviewSummaryForm: () => dispatch(reset("changeSummaryForm")),
  getRecommendedPartners: (recommendedPartnersPage, params) =>
    dispatch(
      getOpenProjectApplicationsRecommendedList(
        {
          ...params,
          status: [APPLICATION_STATUSES.REC].join(","),
          page: recommendedPartnersPage,
          page_size: 5,
        },
        { projectId: ownProps?.projectId },
      ),
    ),
  getPreselectedPartners: (preselectedPartnersPage, params) =>
    dispatch(
      getOpenProjectApplicationsPreselectedList(
        {
          ...params,
          status: [APPLICATION_STATUSES.PRE].join(","),
          page: preselectedPartnersPage,
          page_size: 5,
        },
        { projectId: ownProps?.projectId },
      ),
    ),
  resetUpdateApplicationForm: () => dispatch(reset("updateApplicationForm")),
  updateProjectApplication: (applicationId, body) => dispatch(updateProjectApplication(body, { id: applicationId })),
  getPartnerProjectApplication: () =>
    dispatch(
      getPartnerProjectApplication(undefined, {
        projectId: ownProps?.projectId,
      }),
    ),
  sendForDecision: () => dispatch(sendForDecision(undefined, { id: ownProps?.projectId })),
  getApplicationFeedbacks: (applicationId) => dispatch(getApplicationFeedbackList(undefined, { applicationId })),
  getProjectApplicationDetails: (applicationId) => dispatch(getProjectApplicationDetails(undefined, { id: applicationId })),
});
export default connect(mapStateToProps, mapDispatchToProps)(OpenProjectResults);
