import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import FormHelperText from "@mui/material/FormHelperText";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { getColorByHighlightType } from "@local/utils";
import style from "./style.module.scss";

const FieldSelectSearch = ({
  // Standard props
  id,
  label,
  options,
  valueField, // e.g. "id"
  labelField, // e.g. "label"
  multiple,
  readOnly,
  small,
  helperText,
  informationText,
  meta,
  highlightType,
  showEmptyElement = true,

  // Redux Form (or Final Form) props
  input: { value, onChange },
  onChangeExtra,

  // Optional MUI Autocomplete customization
  filterOptions,
}) => {
  // 1) Convert all options’ IDs to strings to be safe
  const computedOptions = options.map((item) => ({
    ...item,
    [valueField]: item[valueField]?.toString(),
  }));

  // 2) If single select & showEmptyElement, add an empty “placeholder” option
  let finalOptions = computedOptions;
  if (!multiple && showEmptyElement) {
    const emptyElement = {
      [valueField]: "", // or null
      [labelField]: <em>{formatMessage({ id: "cmp.field.select.idx.empty" })}</em>,
    };
    finalOptions = [emptyElement, ...computedOptions];
  }

  // 3) Build the Autocomplete "value":
  //    - If multiple, we need an array of objects.
  //    - If single, we need a single object (or null).
  let autoCompleteValue;
  if (multiple) {
    // The form’s value is presumably an array of IDs. Convert each ID to the matching option object.
    autoCompleteValue = Array.isArray(value)
      ? value
          .map((idOrObj) => {
            const idString = typeof idOrObj === "object" ? idOrObj[valueField] : idOrObj;
            return finalOptions.find((opt) => opt[valueField] === idString?.toString());
          })
          .filter(Boolean) // remove any unmatched
      : [];
  } else {
    // The form’s value is presumably a single ID. Convert it to the matching option object.
    const singleId = typeof value === "object" ? value[valueField] : value;
    autoCompleteValue = finalOptions.find((opt) => opt[valueField] === singleId?.toString()) || null;
  }

  // 4) Handle changes:
  //    - Convert the selected object(s) back to IDs for the form state.
  const handleChange = (event, newValue) => {
    if (multiple) {
      // newValue is an array of option objects. Store an array of IDs in the form.
      const newIds = newValue.map((option) => option[valueField]);
      onChange(newIds);
      if (onChangeExtra) onChangeExtra(newIds);
    } else {
      // newValue is a single option object (or null). Store the ID in the form.
      const newId = newValue ? newValue[valueField] : null;
      onChange(newId);
      if (onChangeExtra) onChangeExtra(newId);
    }
  };

  // (Optional) Color highlight
  const color = getColorByHighlightType(highlightType);

  return (
    <div className={style.selectWrapper}>
      <Autocomplete
        id={id}
        multiple={multiple}
        disabled={readOnly}
        value={autoCompleteValue}
        onChange={handleChange}
        options={finalOptions}
        filterOptions={filterOptions}
        getOptionLabel={(option) =>
          // If we used an empty placeholder, ensure it doesn't break
          option[labelField] ? option[labelField].toString() : ""
        }
        isOptionEqualToValue={(option, val) => option[valueField] === val[valueField]}
        renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => <Chip key={option[valueField]} label={option[labelField]} size={small ? "small" : "medium"} {...getTagProps({ index })} />)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            error={Boolean(meta?.error)}
            placeholder={helperText || ""}
            size={small ? "small" : "normal"}
            InputProps={{
              ...params.InputProps,
              style: { backgroundColor: `${color}29` },
            }}
          />
        )}
      />

      {/* Helper Text */}
      {helperText && !meta?.error && <FormHelperText id={`select_helper_text_${id}`}>{helperText}</FormHelperText>}
      {meta?.error && (
        <FormHelperText error id={`field-text-helper-text-${id}`}>
          {meta?.error}
        </FormHelperText>
      )}

      {/* Info tooltip */}
      {informationText && (
        <div className={style.tooltipWrapper}>
          <Tooltip title={informationText}>
            <InfoIcon style={{ fontSize: ".8rem", color: "#555555" }} />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

FieldSelectSearch.propTypes = {};

export default FieldSelectSearch;
